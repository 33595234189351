<i18n>
{
  "de": {
    "header": {
      "event": "Ausstehende Aktion",
      "buildingIdentifier": "Identifikation",
      "buildingDescription": "Bezeichnung"
    },
    "transitions": {
      "PENDING_REPORTER": "Wird Reporter",
      "PENDING_ARCHIVED": "Wird Archiviert"
    },
    "noPendingChanges": "Keine ausstehende Änderungen",
    "selectAllLabel": "Alle auswählen",
    "selectNoneLabel": "Auswahl entfernen",
    "applySelectedChanges": "Ausgewählte Änderungen anwenden",
    "applyAllChanges": "Alle Änderungen anwenden",
    "confirmApplyAllChanges": "Sind Sie sicher, dass Sie alle ausstehenden Änderungen anwenden möchten?"
  }
}
</i18n>

<template>
  <div class="c-status-pending-changes-table">
    <div v-if="isAdmin && pendingChanges.length > 0" class="buttons">
      <ButtonWrapper>
        <div>
          <CheckInput
            v-model="isAllBuildingsSelected"
            :edit="true"
            :label="$t('selectAllLabel')"
            @input="onToggleSelectAllBuildings"
          />
        </div>
        <button v-if="selectedBuildingsCount > 0" type="button" class="button" @click="onSelectNone">
          {{ $t('selectNoneLabel') }}
        </button>
      </ButtonWrapper>

      <ButtonWrapper>
        <button v-if="selectedBuildingsCount > 0" type="button" class="button" @click="onApplySelectedChanges">
          {{ $t('applySelectedChanges') }}
        </button>
        <button v-else type="button" class="button" @click="onApplyAllChanges">
          {{ $t('applyAllChanges') }}
        </button>
      </ButtonWrapper>
    </div>

    <DetailList :has-header="pendingChanges.length > 0">
      <template #header>
        <div v-if="isAdmin" class="select"></div>
        <div>{{ $t('header.buildingIdentifier') }}</div>
        <div>{{ $t('header.buildingDescription') }}</div>
        <div>{{ $t('header.event') }}</div>
      </template>

      <template v-if="pendingChanges.length === 0">
        <div class="empty">{{ $t('noPendingChanges') }}</div>
      </template>

      <template v-else>
        <li v-for="(change, index) in pendingChanges" :key="change.id">
          <span v-if="isAdmin" class="select">
            <CheckInput v-model="selectedBuildings[index]" :edit="true" @input="checkBuildingCounts" />
          </span>
          <div>
            <router-link :to="`/portfolios/${portfolio.id}/buildings/${change.buildingId}`">
              {{ change.buildingIdentifier }}
            </router-link>
          </div>
          <div>
            <router-link :to="`/portfolios/${portfolio.id}/buildings/${change.buildingId}`">
              {{ change.buildingDescription }}
            </router-link>
          </div>
          <div>{{ $t(`transitions.${change.statusChangePending}`) }}</div>
        </li>
      </template>
    </DetailList>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import DetailList from '@/components/shared/lists/DetailList.vue'
import CheckInput from '@/components/shared/forms/CheckInput.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'

export default {
  components: {
    DetailList,
    CheckInput,
    ButtonWrapper,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    pendingChanges: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedBuildings: [],
      isAllBuildingsSelected: false,
    }
  },

  computed: {
    isAdmin() {
      return this.$store.getters['account/admin']
    },

    selectedBuildingsCount() {
      return this.selectedBuildings.filter((b) => b).length
    },
  },

  methods: {
    ...mapActions({
      applyStatusChange: 'statusEvents/applyStatusChange',
    }),

    //
    onToggleSelectAllBuildings() {
      if (this.isAllBuildingsSelected) {
        this.onSelectAll()
      } else {
        this.onSelectNone()
      }
    },

    //
    checkBuildingCounts() {
      this.isAllBuildingsSelected = this.selectedBuildingsCount === this.pendingChanges.length
    },

    //
    onSelectAll() {
      this.selectedBuildings = this.pendingChanges.map((b) => true)
    },

    //
    onSelectNone() {
      this.selectedBuildings = this.pendingChanges.map((b) => false)
      this.isAllBuildingsSelected = false
    },

    //
    onApplySelectedChanges() {
      const ids = this.pendingChanges.filter((_, index) => this.selectedBuildings[index]).map((b) => b.buildingId)
      this.applyStatusChange({
        portfolioId: this.portfolio.id,
        ids,
      })
    },

    //
    onApplyAllChanges() {
      if (confirm(this.$t('confirmApplyAllChanges'))) {
        const ids = this.pendingChanges.map((b) => b.buildingId)
        this.applyStatusChange({
          portfolioId: this.portfolio.id,
          ids,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-status-pending-changes-table {
  & .empty {
    padding: 10px;
    text-align: center;
  }

  & .buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  & .detail-list > ul > li,
  & .detail-list > ul > li.header {
    display: flex;
    flex-direction: row;

    & .select {
      flex: 0 0 40px;
    }

    & > * {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
