<!--
Required: portfolio_id
Provides: statusEvents, statusPendingChanges
-->
<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState({
      statusEventsLoading: (state) => state.statusEvents.loadingStatusEvents,
      statusEventsLoaded: (state) => state.statusEvents.loadedStatusEvents,
      statusPendingChangesLoading: (state) => state.statusEvents.loadingStatusPendingChanges,
      statusPendingChangesLoaded: (state) => state.statusEvents.loadedStatusPendingChanges,
      statusEventsError: (state) => state.statusEvents.error,
    }),

    ...mapGetters({
      __statusEvents: 'statusEvents/getStatusEvents',
      __statusPendingChanges: 'statusEvents/getStatusPendingChanges',
    }),

    statusEvents() {
      return this.__statusEvents()
    },

    statusPendingChanges() {
      return this.__statusPendingChanges()
    },
  },

  created() {
    this.__reset()
    this.__getStatusEventsByPortfolioId({
      portfolioId: this.portfolio_id,
    })
    this.__getStatusPendingChangesByPortfolioId({
      portfolioId: this.portfolio_id,
    })
  },

  methods: {
    ...mapActions({
      __getStatusEventsByPortfolioId: 'statusEvents/getStatusEventsByPortfolioId',
      __getStatusPendingChangesByPortfolioId: 'statusEvents/getStatusPendingChangesByPortfolioId',
      __reset: 'statusEvents/reset',
    }),
  },
}
</script>
