<i18n>
{
  "de": {
    "noEvents": "Keine Einträge",
    "unknownUser": "System"
  }
}
</i18n>

<template>
  <DetailList v-if="statusEvents" :has-header="events.length > 0" class="c-status-events-table">
    <template #header>
      <div>{{ $t('pricing.header.eventType') }}</div>
      <div>{{ $t('pricing.header.buildingIdentifier') }}</div>
      <div>{{ $t('pricing.header.buildingDescription') }}</div>
      <div>{{ $t('pricing.header.user') }}</div>
      <div class="align-right">{{ $t('pricing.header.buildings') }}</div>
      <div class="align-right">{{ $t('pricing.header.planner') }}</div>
      <div class="align-right">{{ $t('pricing.header.reporter') }}</div>
      <div class="align-right">{{ $t('pricing.header.archived') }}</div>
      <div class="align-right">{{ $t('pricing.header.timestamp') }}</div>
    </template>

    <template v-if="statusEvents && events.length === 0">
      <div class="empty">{{ $t('noEvents') }}</div>
    </template>

    <template v-else>
      <!-- Starting point at the top -->
      <!-- <li v-if="startingPoint" class="snapshot">
        <div>{{ $t('pricing.body.type.CARRY_FORWARD') }}</div>
        <div></div>
        <div></div>
        <div></div>
        <div class="align-right">{{ startingPoint.startingCountSum }}</div>
        <div class="align-right">
          <span class="status-pill planner">
            {{ startingPoint.startingCount.planner }}
          </span>
        </div>
        <div class="align-right">
          <span class="status-pill reporter">
            {{ startingPoint.startingCount.reporter }}
          </span>
        </div>
        <div class="align-right">
          <span class="status-pill archived">
            {{ startingPoint.startingCount.archived }}
          </span>
        </div>
        <div class="align-right">{{ new Date(startingPoint.createdAt).toLocaleString('de-CH') }}</div>
      </li> -->

      <template v-for="(event, index) in events">
        <!-- Snapshots -->
        <li v-if="event.isSnapshot" :key="`${index}-snapshot`" class="snapshot">
          <div>{{ $t(`pricing.body.type.SNAPSHOT`) }}</div>
          <div></div>
          <div></div>
          <div></div>
          <div class="align-right">{{ event.currentCountSum }}</div>
          <div class="align-right">
            <span class="status-pill planner">
              {{ event.currentCount.planner }}
            </span>
          </div>
          <div class="align-right">
            <span class="status-pill reporter">
              {{ event.currentCount.reporter }}
            </span>
          </div>
          <div class="align-right">
            <span class="status-pill archived">
              {{ event.currentCount.archived }}
            </span>
          </div>
          <div class="align-right">{{ new Date(event.createdAt).toLocaleString('de-CH') }}</div>
        </li>

        <!-- Events -->
        <li v-else :key="`${index}-event`">
          <div :title="$t(`pricing.body.eventType.${event.type}`)">
            {{ $t(`pricing.body.eventType.${event.type}`) }}
          </div>
          <div>
            <router-link v-if="event.buildingId" :to="`/portfolios/${portfolio.id}/buildings/${event.buildingId}`">
              {{ event.buildingIdentifier }}
            </router-link>
            <span v-else>{{ event.buildingIdentifier }}</span>
          </div>
          <div>
            <router-link
              v-if="event.buildingId"
              :to="`/portfolios/${portfolio.id}/buildings/${event.buildingId}`"
              :title="event.buildingDescription"
            >
              {{ event.buildingDescription }}
            </router-link>
            <span v-else :title="event.buildingDescription">{{ event.buildingDescription }}</span>
          </div>
          <div v-if="event.userFirstName !== null && event.userLastName !== null">
            {{ `${event.userFirstName} ${event.userLastName}` }}
          </div>
          <div v-else>{{ $t('unknownUser') }}</div>
          <div class="align-right">{{ event.currentCountSum }}</div>
          <div class="align-right">
            <span class="status-pill planner">
              {{ event.currentCount.planner }}
            </span>
          </div>
          <div class="align-right">
            <span class="status-pill reporter">
              {{ event.currentCount.reporter }}
            </span>
          </div>
          <div class="align-right">
            <span class="status-pill archived">
              {{ event.currentCount.archived }}
            </span>
          </div>
          <div class="align-right">{{ new Date(event.createdAt).toLocaleString('de-CH') }}</div>
        </li>
      </template>

      <!-- Starting point at the bottom -->
      <li v-if="startingPoint" class="snapshot">
        <div>{{ $t('pricing.body.type.CARRY_FORWARD') }}</div>
        <div></div>
        <div></div>
        <div></div>
        <div class="align-right">{{ startingPoint.startingCountSum }}</div>
        <div class="align-right">
          <span class="status-pill planner">
            {{ startingPoint.startingCount.planner }}
          </span>
        </div>
        <div class="align-right">
          <span class="status-pill reporter">
            {{ startingPoint.startingCount.reporter }}
          </span>
        </div>
        <div class="align-right">
          <span class="status-pill archived">
            {{ startingPoint.startingCount.archived }}
          </span>
        </div>
        <div class="align-right">{{ new Date(startingPoint.createdAt).toLocaleString('de-CH') }}</div>
      </li>
    </template>
  </DetailList>
</template>

<script>
import DetailList from '@/components/shared/lists/DetailList.vue'

export default {
  components: {
    DetailList,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    statusEvents: {
      type: Object,
    },
  },

  data() {
    return {
      events: [],
    }
  },

  computed: {
    startingPoint() {
      if (this.statusEvents?.carryForward !== null) {
        return {
          startingCount: this.statusEvents.carryForward.currentCount,
          startingCountSum:
            this.statusEvents.carryForward.currentCount.planner +
            this.statusEvents.carryForward.currentCount.reporter +
            this.statusEvents.carryForward.currentCount.archived,
          createdAt: this.statusEvents.carryForward.validAt,
        }
      }
      return undefined
    },
  },

  watch: {
    statusEvents() {
      this.events = this.processEvents(this.statusEvents)
    },
  },

  mounted() {
    this.events = this.processEvents(this.statusEvents)
  },

  methods: {
    processEvents() {
      if (!this.statusEvents) {
        return []
      }
      // Placeholders
      let processedEvents = []
      let processedSnapshots = []
      // Process events
      if (this.statusEvents?.events?.length > 0) {
        processedEvents = this.statusEvents.events.map((event) => {
          return {
            ...event,
            currentCountSum: event.currentCount.planner + event.currentCount.reporter + event.currentCount.archived,
            isSnapshot: false,
          }
        })
      }
      // Process snapshots
      if (this.statusEvents?.snapshots?.length > 0) {
        processedSnapshots = this.statusEvents.snapshots.map((snapshot) => {
          return {
            ...snapshot,
            currentCountSum:
              snapshot.currentCount.planner + snapshot.currentCount.reporter + snapshot.currentCount.archived,
            isSnapshot: true,
          }
        })
      }
      const allEvents = [...processedEvents, ...processedSnapshots]
      if (allEvents.length > 0) {
        // Sort events by date (newest first)
        return allEvents.sort((a, b) => {
          return (
            new Date(b.createdAt) - new Date(a.createdAt) ||
            parseFloat(b.createdAt.slice(20, 26)) - parseFloat(a.createdAt.slice(20, 26))
          ) // microsecond comparison
        })
      }
      return []
    },
  },
}
</script>

<style lang="scss" scoped>
.c-status-events-table {
  & .empty {
    padding: 10px;
    text-align: center;
  }

  & ul > li > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & ul > li.header > * {
    display: block;
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .status-pill {
    font-weight: 700;
    letter-spacing: 2px;
    margin-left: auto;
    padding: 0 var(--spacing-xs) 0 8px;
    border-radius: 13px;

    &.planner {
      color: #1a9900;
      background-color: #1a99001a;
    }

    &.reporter {
      color: #008099;
      background-color: #0080991a;
    }

    &.archived {
      color: #777777;
      background-color: #7777771a;
    }
  }

  & .snapshot {
    background-color: #f4f4f4;
  }
}
</style>
